/**
 * abbreviate number to K, M, B, T suffix
 */
export function formatNumber(value: number) {
  const formatter = new Intl.NumberFormat(undefined, {
    // maximumSignificantDigits: 2,
    useGrouping: false,
  });

  value = parseInt(formatter.format(value), 10);

  let newValue = value;
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  let res = newValue.toString().length > 2 ? newValue.toPrecision(3) : newValue.toPrecision();

  res += suffixes[suffixNum];
  return res;
}

/**
 * format seconds to human readable time
 *
 * @example formatTime(35) => "35s"
 * @example formatTime(65) => "1:05"
 */
export function formatTime(seconds: number, secondsOnlyUnder60 = true) {
  const rounded = Math.round(seconds * 10) / 10;

  if (rounded < 60 && secondsOnlyUnder60) {
    return `${rounded}s`;
  }

  const format = new Date(rounded * 1000).toISOString().substring(11, 19);

  if (format.startsWith("00:")) {
    return format.substring(3);
  }

  return format;
}
