import { initClient, initContract } from "@ts-rest/core";
import { createNextHandler, tsr } from "@ts-rest/serverless/next";
import { errorHandler } from "./errors";
import { dailyRewardApi } from "@/app/(signed-in)/create/reward-api-contract";
import { notificationsApi } from "@/shared/notifications/feed-api-contract";
import { templatesApi } from "@/shared/templates/templates-api-contract";

const contractRouter = initContract();

export const mainApiContract = contractRouter.router(
  {
    notifications: notificationsApi.contract,
    templates: templatesApi.contract,
    dailyReward: dailyRewardApi.contract,
  },
  { strictStatusCodes: true }
);

const routerHandlers = tsr.router(mainApiContract, {
  notifications: notificationsApi.handler,
  templates: templatesApi.handler,
  dailyReward: dailyRewardApi.handler,
});

export const mainApiHandler = createNextHandler(mainApiContract, routerHandlers, {
  basePath: "/api",
  jsonQuery: true,
  responseValidation: true,
  handlerType: "app-router",
  errorHandler: errorHandler,
});

// using the fetch client instead of react-query client to decouple the dependencies.
// otherwise, we have to use the version of react query that is compatible with the ts-rest
export const apiClient = initClient(mainApiContract, {
  baseUrl: "/api",
  baseHeaders: {},
});
