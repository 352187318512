import { initContract } from "@ts-rest/core";
import { tsr } from "@ts-rest/serverless/next";
import { RewardState } from "./reward-banner";
import { claimReward, getRewardState } from "./reward-banner-action";
import { protectedHandler } from "@/server/context";

const c = initContract();

const contract = c.router(
  {
    getStatus: {
      method: "GET",
      path: "/status",
      responses: {
        200: c.type<{ state: RewardState }>(),
      },
    },
    claim: {
      method: "PUT",
      path: "/claim",
      body: null,
      responses: {
        201: null,
      },
    },
  },
  { strictStatusCodes: true, pathPrefix: "/daily-reward" }
);

const handlers = tsr.router(contract, {
  getStatus: protectedHandler(async (args) => {
    const { state } = await getRewardState();

    return {
      status: 200,
      body: { state: state },
    };
  }),
  claim: protectedHandler(async (args) => {
    await claimReward();

    return {
      status: 201,
      body: null,
    };
  }),
});

export const dailyRewardApi = {
  contract: contract,
  handler: handlers,
};
