import { initContract } from "@ts-rest/core";
import { tsr } from "@ts-rest/serverless/next";
import { z } from "zod";
import { fetchNotifications, fetchUnseenCount, updateNotifications } from "./feed-action";
import { protectedHandler, publicHandler } from "@/server/context";

const c = initContract();

const contract = c.router(
  {
    getMany: {
      method: "GET",
      path: "",
      query: z.object({
        offset: z.number().optional(),
        limit: z.number().optional(),
      }),
      responses: {
        200: c.type<{ items: Awaited<ReturnType<typeof fetchNotifications>> }>(),
      },
    },
    getUnseenCount: {
      method: "GET",
      path: "/unseen-count",
      responses: {
        200: c.type<{ count: Awaited<ReturnType<typeof fetchUnseenCount>> }>(),
      },
    },
    updateMany: {
      method: "PUT",
      path: "",
      body: z.object({
        state: z.enum(["read", "seen"]),
        ids: z.array(z.string()),
      }),
      responses: {
        201: null,
      },
    },
  },
  { strictStatusCodes: true, pathPrefix: "/notifications" }
);

const handlers = tsr.router(contract, {
  getMany: protectedHandler(async (args) => {
    const data = {
      limit: args.query.limit,
      offset: args.query.offset,
      userId: args.ctx.user.id,
    };

    const notifications = await fetchNotifications(data);

    return {
      status: 200,
      body: { items: notifications },
    };
  }),
  getUnseenCount: protectedHandler(async (args) => {
    const count = await fetchUnseenCount(args.ctx.user.id);

    return {
      status: 200,
      body: { count: count },
    };
  }),
  updateMany: protectedHandler(async (args) => {
    await updateNotifications(args.ctx.user.id, args.body.ids, args.body.state);

    return {
      status: 201,
      body: null,
    };
  }),
});

export const notificationsApi = {
  contract: contract,
  handler: handlers,
};
