import { useEffect, useState } from "react";

interface UseOnScreenResponse<T extends Element> {
  isVisible: boolean;
  ref: T | null;
  setRef: (node: T | null) => void;
}

/**
 * Hook to detect when an element is visible in the viewport
 *
 * @example
 * const { ref, setRef, isVisible } = useOnScreen(0.5);
 * return <div ref={setRef}>{isVisible ? 'Visible' : 'Not visible'}</div>;
 */
export function useOnScreen<T extends Element>(threshold = 0.9): UseOnScreenResponse<T> {
  const [isVisible, setIsVisible] = useState(false);
  const [ref, setRef] = useState<T | null>(null);

  useEffect(() => {
    if (globalThis.IntersectionObserver === undefined) return;

    if (!ref) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(!!entry?.isIntersecting);
      },
      { threshold }
    );

    observer?.observe(ref);
    return () => observer?.disconnect();
  }, [ref]);

  return { ref: ref, setRef: setRef, isVisible: isVisible };
}
