import { initContract } from "@ts-rest/core";
import { tsr } from "@ts-rest/serverless/next";
import { z } from "zod";
import { fetchSimilarTemplates, fetchTemplates } from "./templates-action";
import { publicHandler } from "@/server/context";

const c = initContract();

const contract = c.router(
  {
    getMany: {
      method: "GET",
      path: "",
      query: z.object({
        offset: z.number().optional(),
        limit: z.number().optional(),
        filter: z.string().optional(),
        searchQuery: z.string().optional(),
      }),
      responses: {
        200: c.type<{ items: Awaited<ReturnType<typeof fetchTemplates>> }>(),
      },
    },
    getSimilar: {
      method: "GET",
      path: "/:id/similar",
      query: z.object({
        offset: z.number().optional(),
        limit: z.number().optional(),
      }),
      responses: {
        200: c.type<{ items: Awaited<ReturnType<typeof fetchTemplates>> }>(),
      },
    },
  },
  { strictStatusCodes: true, pathPrefix: "/templates" }
);

const handlers = tsr.router(contract, {
  getMany: publicHandler(async (args) => {
    const templates = await fetchTemplates(args.query);

    return {
      status: 200,
      body: { items: templates },
    };
  }),
  getSimilar: publicHandler(async (args) => {
    const templates = await fetchSimilarTemplates(
      args.params.id,
      args.query.limit,
      args.query.offset
    );

    return {
      status: 200,
      body: { items: templates },
    };
  }),
});

export const templatesApi = {
  contract: contract,
  handler: handlers,
};
