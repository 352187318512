// https://gist.github.com/bnsngltn/0ac0236e38acadcb22fbe5ed071f3964

import { UnauthenticatedError } from "./errors";
import { getCurrentSession } from "@/helpers/session";
import { logger } from "@/shared/logger";

// Inspired from the context creation in tRPC
async function createBaseCtx<T>(args: T) {
  const session = await getCurrentSession();

  const user = session?.user;

  return { user };
}
type PublicCtx = Awaited<ReturnType<typeof createBaseCtx>>;

// Builds over the base context, basically still inspired from tRPC
// The API for this function can be changed though so that they can be chained together
async function createProtectedCtx<T>(args: T) {
  const { user } = await createBaseCtx(args);
  // Make sure to handle this error on the file in which you called `createNextRouter`
  // This might be an anti pattern for now since this is not directly reflected on the contract
  if (!user) {
    throw new UnauthenticatedError();
  }

  if (!user.id) {
    logger.warn(`User does not have an ID: ${JSON.stringify(user)}`);
  }

  return { user };
}
type ProtectedCtx = Awaited<ReturnType<typeof createProtectedCtx>>;

export function publicHandler<Args, Res>(
  handler: (args: Args & { ctx: PublicCtx }) => Promise<Res>
) {
  return async (args: Args) => {
    const ctx = await createBaseCtx(args);
    return handler({ ...args, ...{ ctx: ctx } });
  };
}

// Only authenticated users can access
export function protectedHandler<Args, Res>(
  handler: (args: Args & { ctx: ProtectedCtx }) => Promise<Res>
) {
  return async (args: Args) => {
    const ctx = await createProtectedCtx(args);
    return handler({ ...args, ...{ ctx: ctx } });
  };
}
